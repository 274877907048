<template>
  <div>
    <v-data-table
      v-model="selectedFiles"
      :headers="headers"
      :items="filesAndCareLocations"
      sort-by="type"
      class="elevation-1"
      :items-per-page="10"
      show-select
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-card-title>{{ $t("database.selected.title") }}</v-card-title>
        </v-toolbar>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <v-container v-if="item.editing">
              <v-text-field
                :label="item.name"
                solo
                dense
                v-model="item.newName"
              >
              <template v-slot:append-outer>
                <v-icon @click="item.editing=false" color="grey" class="ml-4">
                mdi-content-save
              </v-icon>
              <v-icon @click="item.newName = item.name; item.editing=false" color="grey" class="ml-4">
                mdi-close-box
              </v-icon>
              </template>
            </v-text-field>


        </v-container>
        <v-container v-else>
          <span v-if="item.name === item.newName || item.newName === ''">
            {{ item.name }}
          </span>
          <span v-else>
            <span style="color: lightgrey">{{ item.name }}</span> → {{ item.newName }}
          </span>
          <v-icon @click="closeAllEditing(); item.editing=true" color="grey" class="ml-4">
            mdi-pencil
          </v-icon>
        </v-container>
      </template>
      <template v-slot:[`item.addedCareLocations`]="{ item }">
        <div v-if="item.addedCareLocations.length < 1">
          <span style="font-style: italic; color: lightgrey;">Geen folder gekozen</span>
        </div>
        <ul v-else>
          <li v-for="location in item.addedCareLocations" :key="location">
          {{ location }}
          </li>
        </ul>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="askToConfirmDeleteItem(item)" color="red" class="pl-4">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <div class="text-right pt-2">
      <v-btn
        class="ma-2 white--text"
        color="grey darken-2"
        @click="toShowCareLocationsDialog"
      >
        {{ $t("database.selected.add_locations") }}
      </v-btn>

      <v-btn @click="showReturnDialog = true;" class="ma-2" color="primary">
        <v-icon dark left> mdi-arrow-left </v-icon>{{ $t("database.selected.previous_page") }}
      </v-btn>

      <v-btn
        class="ma-2"
        color="green darken-1"
        dark
        @click="copyFilesAndCareLocationsToServer"
      >
        {{ $t("database.selected.finish") }}
        <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
      </v-btn>
    </div>
    



    <v-dialog v-model="showCareLocationsDialog" max-width="800px">


  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step
        :complete="e1 > 1"
        step="1"
      >
        Kies organisatie
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 2"
        step="2"
      >
        Kies Locatie
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step 
        :complete="e1 > 3"
        step="3">
        Kies afdeling
      </v-stepper-step>
      
      <v-divider></v-divider>

      <v-stepper-step 
        :complete="e1 > 4"
        step="4">
        Kies type folder
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="5">
        Kies folder
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-text-field
            append-icon="mdi-magnify"
            :label="$t('database.selected.stepper.organisation.search')"
            single-line
            hide-details
            class="px-5"
            @input="searchOrganisations"
            v-model="searchString"
        ></v-text-field>
        <v-list dense>
          <v-list-item-group
            v-model="selectedOrganisation"
            @change="pickOrganisation"
          >
            <v-list-item v-for="organisation in shownOrganisations" :key="organisation">
              <v-list-item-content>
                {{ organisation }}
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-list dense>
          <v-list-item-group
            v-model="selectedLocation"
            @change="pickLocation"
          >
            <v-list-item v-for="location in locations" :key="location.name">
              <v-list-item-content>
                {{ location.name }}
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-btn
          class="ma-2"
          color="grey darken-1"
          dark
          @click="e1--; this.selectedOrganisation = undefined;"
        >
          Terug
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-list dense>
          <v-list-item-group
            v-model="selectedSystem"
            @change="pickSystem"
          >
            <v-list-item v-for="system in systems" :key="system.name">
              <v-list-item-content style="display: inline;">
                {{ system.department }} <span style="color: #BDBDBD; font-size: 0.9em;">({{ system.name }})</span>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-btn
          class="ma-2"
          color="grey darken-1"
          dark
          @click="e1--; this.selectedLocation = undefined;"
        >
          Terug
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="4">
        <v-container d-flex justify-space-between>
              <v-btn height="125" width="200" @click="pickResidentFolder">
                <v-icon large class="mr-3">mdi-account-group-outline</v-icon>
                Bewoners
              </v-btn>
              <v-btn height="125" width="200" @click="pickLocationFolder">
                <v-icon large class="mr-3">mdi-hospital-building</v-icon>
                Zorghuis
              </v-btn>
              <v-btn height="125" width="200" @click="pickDayFolder">
                <v-icon large class="mr-3">mdi-calendar</v-icon>
                Dagstructuur
              </v-btn>
        </v-container>
        <v-btn
          class="ma-2"
          color="grey darken-1"
          dark
          @click="e1--; this.selectedSystem = undefined"
        >
          Terug
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="5">
        <file-browser
            :files="files"
            v-model="chosenFiles"
            root="anything"
            ></file-browser>
          <div class="d-flex justify-space-between">
          <v-btn
            class="ma-2"
            color="grey darken-1"
            dark
            @click="e1--; this.folderType = undefined;"
          >
            Terug
          </v-btn>
          <v-btn
            class="ma-2"
            color="green darken-1"
            dark
            @click="selectDirectory"
          >
            Map selecteren
          </v-btn>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
    </v-dialog>






    <v-row dialogSelectACareLocation="true" justify="center">
      <v-dialog v-model="dialogSelectACareLocation" persistent max-width="550">
        <template> </template>
        <v-card>
          <v-card-title class="text-h5">
            {{ $t("database.selected.no_location.title") }}
          </v-card-title>
          <v-card-text>
            {{ $t("database.selected.no_location.text") }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="dialogSelectACareLocation = false"
            >
              {{ $t("database.ok") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    
    <v-dialog v-model="showNoFilesSelectedError" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("database.selected.no_files.text") }}
        </v-card-title>
        <v-card-text>
          {{ $t("database.selected.no_files.text") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="showNoFilesSelectedError = false">
            {{ $t("database.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showReturnDialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("database.selected.return.title") }}
        </v-card-title>
        <v-card-text>
          {{ $t("database.selected.return.text") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" dark to="/database">
            {{ $t("database.selected.return.affirm") }}
          </v-btn>
          <v-btn color="grey darken-1" dark @click="showReturnDialog = false;">
            {{ $t("database.selected.return.cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showConfirmDeleteItem" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("database.selected.confirm_delete") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showConfirmDeleteItem = false">
            {{ $t("database.cancel") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="ConfirmDeleteItem">
            {{ $t("database.ok") }}
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showUploadedSuccessfullyDialog" persistent max-width="500">
      <template> </template>
      <v-card>
        <v-card-title class="text-h5"> {{ $t("database.selected.files_uploaded.title") }} </v-card-title>
        <v-card-text>{{ $t("database.selected.files_uploaded.text") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="returnToDatabase">
            {{ $t("database.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import FileBrowser from '@/components/fileBrowser.vue';
import { ILLI_API_SERVER_URL } from "../../config";

export default {
  components: {
    FileBrowser
  },
  data() {
    return {
      showCareLocationsDialog: false,
      showConfirmDeleteItem: false,
      dialogSelectACareLocation: false,
      showUploadedSuccessfullyDialog: false,
      showNoFilesSelectedError: false,
      showReturnDialog: false,
      isLoading: false,
      e1: 1,
      organisations: [],
      shownOrganisations: [],
      locations: [],
      systems: [],
      searchString: "",
      selectedOrganisation: undefined,
      selectedLocation: undefined,
      selectedSystem: undefined,
      files: [],
      path: [],
      current: {location: null, organisation: null},
      selectedFiles: [],
      chosenFiles:{},
      selectedCareLocationCheckBox: [],
      folderType: undefined,
      headers: [
        { text: this.$t("database.selected.table_headers.file_name"), value: "name" },
        { text: this.$t("database.selected.table_headers.locations"), value: "addedCareLocations" },
        { text: this.$t("database.selected.table_headers.remove"), value: "actions", sortable: false },
      ],
      careLocationHeaders: [
        { text: this.$t("database.selected.table_headers.organisations"), value: "organisation" },
        { text: this.$t("database.selected.table_headers.locations"), value: "location" },
        { text: this.$t("database.selected.table_headers.private_directory"), value: "private" },
      ],
      filesAndCareLocations: [],
      search: "",
      indexOfSelectedFile: -1,
      selectedContentIndex: -1,
      careLocations: []
    };
  },

  created() {
    if(this.$store.state.selected.length === 0)
      this.$router.replace("/database")
    this.initialize();
  },

  methods: {
    initialize() {
      // this.listSystems()
      this.getOrganisations()
      this.filesAndCareLocations = this.$store.state.selected.map((file, index) => {
        let newName = ""
        if(file.name.split("").includes(" "))
          newName = file.name.replace(/ /g, "-",)
        else
          newName = file.name
        return {
          index: index,
          name: file.name,
          addedCareLocations: [],
          id: index,
          path: file.path,
          isDir: file.isDir,
          editing: false,
          newName: newName
        }
      })
    },

    getOrganisations() {
      axios.get(`${ILLI_API_SERVER_URL}/organisations`)
      .then(function ({data}) {
        this.organisations = data.organisations.map(o=>o.organisation).sort(this.sortStringAlphabetically)
        this.shownOrganisations = this.organisations
        console.log(this.organisations)
      }.bind(this))
    },

    searchOrganisations(search) {
      this.shownOrganisations = this.organisations
      this.loading = true;
      if(search === "") return this.shownOrganisations = this.organisations;
      if(search.length < 2) return false;
      this.shownOrganisations = this.organisations.filter(organisation => {
          return organisation.match(RegExp(search, "gi"))
      })
      this.loading = false;
    },

    getLocations(organisation) {
      axios.get(`${ILLI_API_SERVER_URL}/locations?organisation=${organisation}`)
      .then(function ({data}) {
        this.locations = data.locations.sort(this.sortAlphabetically)
        console.log(this.locations)
      }.bind(this))
    },

    pickOrganisation() {
      console.log(this.selectedOrganisation)
      console.log(this.shownOrganisations[this.selectedOrganisation])
      this.e1 = 2;
      this.getLocations(this.shownOrganisations[this.selectedOrganisation])
    },

    pickLocation() {
      console.log(this.locations[this.selectedLocation])
      this.e1 = 3;
      this.getSystems(this.shownOrganisations[this.selectedOrganisation], this.locations[this.selectedLocation].name)
    },

    pickSystem() {
      this.e1 = 4;
    },

    pickDayFolder() {
      this.e1 = 5;
      this.folderType = "Dagstructuur";
      this.listDirectories(`${this.systems[this.selectedSystem].private}/Dagstructuur`)
    },

    pickResidentFolder() {
      this.e1 = 5;
      this.folderType = "01-Bewoners";
      this.listDirectories(`${this.systems[this.selectedSystem].private}/01-Bewoners`)
    },
    pickLocationFolder() {
      this.e1 = 5;
      this.folderType = "07-Zorghuis";
      this.listDirectories(`${this.systems[this.selectedSystem].private}/07-Zorghuis`)
    },

    getSystems(organisation, location) {
      this.isLoading = true;
      axios.get(`${ILLI_API_SERVER_URL}/systems?organisation=${organisation}&location=${location}`)
      .then(function ({data:{systems}}) {
        this.isLoading = false;
        this.systems = systems.sort(this.sortPrivateAlphabetically)
        console.log(this.systems)
      }.bind(this));
    },

    selectDirectory() {
      console.log(this.selectedFiles, this.chosenFiles, this.folderType, this.systems[this.selectedSystem].private)
      if(!this.chosenFiles.chosenFiles || this.chosenFiles.chosenFiles.length === 0)
        this.chosenFiles.chosenFiles = [{Path: this.chosenFiles.path.join("/")}]
      this.selectedFiles.forEach(file => {
        this.chosenFiles.chosenFiles.forEach(directory => {
          this.filesAndCareLocations[file.index].addedCareLocations.push([this.systems[this.selectedSystem].private, this.folderType, directory.Path].join('/'))
        })
      })

      this.selectedFiles = [];
      this.chosenFiles.chosenFiles = [];
      this.selectedOrganisation = undefined;
      this.selectedLocation = undefined;
      this.selectedSystem = undefined;
      this.e1 = 1;
      this.selectedCareLocationCheckBox = [];
      this.showCareLocationsDialog = false;
    },

    askToConfirmDeleteItem(item) {
      this.indexOfSelectedFile = this.filesAndCareLocations.indexOf(item);
      this.showConfirmDeleteItem = true;
    },

    ConfirmDeleteItem() {
      this.filesAndCareLocations.splice(this.indexOfSelectedFile, 1);
      this.$store.state.selected.splice(this.indexOfSelectedFile, 1);
      this.filesAndCareLocations.splice(this.selectedContentIndex, 0);
      this.showConfirmDeleteItem = false
    },

    toShowCareLocationsDialog() {
      if (this.selectedFiles.length > 0) {
        this.showCareLocationsDialog = true;
      } else {
        this.showNoFilesSelectedError = true;
      }
    },

    addCareLocations() {
      this.filesAndCareLocations
      .filter(({path}) => this.selectedFiles.map(({path}) => path).includes(path))
      .forEach(location => {
        this.filesAndCareLocations[location.index].addedCareLocations = 
          this.selectedCareLocationCheckBox.map(directory => [...this.path, directory].join('/'))
      })

      this.selectedFiles = [];
      this.selectedCareLocationCheckBox = [];
      this.showCareLocationsDialog = false;
    },

    enterFolder(folder, organisation, location) {
      this.current.organisation = organisation
      this.current.location = location
      this.path.push(folder)
      this.listDirectories()
    },

    previousFolder() {
      this.path.pop();

      if (this.path.length === 0) {
        // this.listSystems()
      } else {
        this.listDirectories()
      }
    },

    copyFilesAndCareLocationsToServer() {
      if (this.filesAndCareLocations.some(({addedCareLocations}) => addedCareLocations.length === 0)) {
        this.dialogSelectACareLocation = true;
      } else {
        this.filesAndCareLocations.forEach(fileWithLocations => {
          fileWithLocations.addedCareLocations.forEach(addedCareLocation => {
            const formData = {
              fileName: fileWithLocations.path,
              careLocationName: addedCareLocation,
              isDir: fileWithLocations.isDir,
              newName: (fileWithLocations.newName !== fileWithLocations.name && fileWithLocations.newName !== "") ? fileWithLocations.path.split("/").slice(0, -1).concat(fileWithLocations.newName).join("/") : undefined
            };

            console.log(formData)

            axios
              .post(`${ILLI_API_SERVER_URL}/copyFiles`, formData)
              .then(function (res) {
                console.log(res.status);
              })
              .catch(() => {});
          })
        })
        this.showUploadedSuccessfullyDialog = true;
      }
    },

    returnToDatabase() {
      this.$router.replace({
        name: 'database',
        params: {new: true}
      })
    },

    listDirectories(path) {
      this.isLoading = true;
      
      axios.get(`${ILLI_API_SERVER_URL}/files/${path}`)
        .then(({data:{list}}) => {
          console.log(list)
          this.files = list.filter(entree => entree.IsDir).sort(this.sortCapitalNameAlphabetically)
          this.isLoading = false;
        })
        .catch(() => {});
    },
    
    sortAlphabetically(a,b) {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
    sortPrivateAlphabetically(a,b) {
      const nameA = a.private.toUpperCase();
      const nameB = b.private.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
    sortCapitalNameAlphabetically(a,b) {
      const nameA = a.Name.toUpperCase();
      const nameB = b.Name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
    sortStringAlphabetically(a,b) {
      const nameA = a.toUpperCase();
      const nameB = b.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },

    editItem(item) {
      console.log(item)
    },

    closeAllEditing() {
      this.filesAndCareLocations.forEach(file => file.editing = false)
    }
  },
};
</script>
