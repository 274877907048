<template>
    <div>
        <v-text-field
            append-icon="mdi-magnify"
            :label="$t('systems.search')"
            single-line
            hide-details
            class="px-5"
            @input="searchFiles"
            v-model="searchString"
        ></v-text-field>

        <div class="d-flex align-center">
            <div class="pa-1 pl-7" >
                <div class="d-inline">
                    <a @click="goBackTo(-1)">{{ root }}</a> 
                    <span class="mx-3">></span>
                </div>
                <div v-for="(folder, index) in path" :key="index" class="d-inline">
                    <a :key="index" @click="goBackTo(index)">{{folder}}</a> 
                    <span v-if="(index !== path.length-1)" class="mx-3">></span>
                </div>
            </div>
            <div class="px-2 ml-auto">
                <v-checkbox
                    @change="selectAll"
                ></v-checkbox>
            </div>
        </div>
        <v-divider></v-divider>
        <v-progress-linear
            indeterminate
            color="primary"
            v-if="loading"
        ></v-progress-linear>
        <v-list>
            <v-list-item-group
                v-model="chosenFiles"
                multiple
            >
                <v-list-item
                    v-for="(file) in directoryFiles"
                    :key="file.Path"
                    :value="file"
                    v-ripple
                >
                    <v-list-item-avatar @click="goIntoFolder(file)">
                        <v-icon class="pr-1 pb-1 mt-1" v-if="file.IsDir">mdi-folder</v-icon>
                        <v-icon class="pr-1 pb-1 mt-1" v-if="!file.IsDir">mdi-file-plus</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content @click="goIntoFolder(file)">
                        <v-list-item-title v-html="file.Name"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-checkbox
                            v-model="chosenFiles"
                            :value="file"
                            @click.stop
                        ></v-checkbox>
                    </v-list-item-action>
                </v-list-item>

                <v-list-item v-if="(directoryFiles.length === 0)">
                    <v-list-item-content>
                        <p class="font-italic">
                            No Files Found
                        </p>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
import path from "path";

export default {
    data() {
        return { 
            path: [],
            directoryFiles: [],
            chosenFiles: [],
            searchString: "",
            loading: false,
        }
    },

    watch: {
        files() {
            this.setContent();
        },
        chosenFiles() {
            this.$emit('input', {chosenFiles: this.chosenFiles, path: this.path})
        },
        path() {
            this.$emit('input', {chosenFiles: this.chosenFiles, path: this.path})
        }
    },
    mounted() {
        this.setContent();
        this.chosenFiles = this.value.chosenFiles || []
    },

    props: {
        files: Array,
        value: Object,
        root: String,
    },

    methods: {
        solveDirName(filePath) {
            const dirName = path.dirname(filePath)
            if (dirName === '.') return ""
            else return dirName
        },
        setContent() {
            this.loading = true;
            this.directoryFiles = this.files.filter(file => {
                return this.solveDirName(file.Path) === this.path.join('/')
            })
            this.loading = false;
        },
        goBackTo (folder) {
            if(folder === -1) this.path = [];
            else this.path = this.path.slice(0,folder+1);
            this.setContent();
        },
        goIntoFolder (folder) {
            if (!folder.IsDir) return false
            if(this.searchString === "")
                this.path.push(folder.Name)
            else {
                this.path = folder.Path.split("/")
                this.searchString = ""
            }
            this.setContent();
        },
        searchFiles(search) {
            this.loading = true;
            search = search.replace(/-/g, "_").replace(/ /g, "-")
            if(search === "") return this.setContent();
            if(search.length < 2) return false;
            this.directoryFiles = this.files.filter(file => {
                return file.Path.match(RegExp(search, "gi"))
            })
            this.loading = false;
        },
        selectAll(select) {
            if(select) {
                this.chosenFiles = this.directoryFiles.map(file => file.Path)
            } else {
                this.chosenFiles = []
            }
        }
    }
}
</script>

<style>

</style>